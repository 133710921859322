






























































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import XButton from "./SimpleButton.vue";

@Component({
  components: { XButton },
})
class Dialog extends Vue {
  @Prop({ default: false }) readonly show!: boolean;

  @Prop({ default: false }) readonly agreeButtonState!: boolean;
  @Prop({ default: false }) readonly agreeButtonLoading!: boolean;
  @Prop({ default: false }) readonly useDisagreeButtonAsIcon!: boolean;

  @Prop({ default: "" }) readonly errorText!: string;

  @Emit()
  public propagateCustomEvt(evtName: string, payload: unknown = null) {
    this.$emit(evtName, { name: evtName, payload });
  }

  @Watch("show")
  public showChanged() {
    this.$store.commit("dialog/setIsOpen", this.show);
  }
}

export default Dialog;
