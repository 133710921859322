import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import User from "@/models/user";

@Module({ namespaced: true })
class UserModule extends VuexModule {
  credentials: User | null = null;

  public get passwordChangeRequired(): boolean {
    return !!(this.credentials && this.credentials["требуетсясменапароля"]);
  }

  get id(): number | null {
    return this.credentials && this.credentials["ид"];
  }

  get month(): string | null {
    return this.credentials && this.credentials["открытыймесяц"];
  }

  get closeMonth(): string | null {
    return this.credentials && this.credentials["закрытыймесяц"];
  }

  get email(): string | null {
    return this.credentials && this.credentials["email"];
  }

  @Mutation
  setUser(credentials: User | null) {
    this.credentials = credentials;
  }
}

export default UserModule;
