import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store/store";
import { Contract, ContractBalance } from "@/models/contract";

@Module({ namespaced: true })
class ContractModule extends VuexModule {
  contract: Contract | null = null;

  get useContract(): boolean {
    return !!this.contract;
  }

  get id(): number | null {
    return this.contract && this.contract["$номерЗаписи"];
  }

  get orgIdOfContractId(): number | null {
    return this.contract && this.contract["организация"];
  }

  get number(): string | null {
    return this.contract && this.contract["номер"];
  }

  get type(): string | null {
    return this.contract && this.contract["видпотребления"];
  }

  get balance(): number {
    if (this.contract) {
      const contractBalance = {
        "счета": this.contract["счета"],
        "авансы": this.contract["авансы"]
      }

      return contractBalance
        ? contractBalance["счета"] - contractBalance["авансы"]
        : NaN;
    }

    return NaN;
  }

  @Mutation
  setContract(contract: Contract) {
    this.contract = contract;
  }
}

export default ContractModule;
