import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { store } from "@/store/store";
import ContractModule from "@/views/contracts/ContractModule.vue";
import MyContracts from "@/views/contracts/MyContracts.vue";
import AppealsForRoot from "@/views/AppealsForRoot.vue";
import AccountSettings from "@/views/AccountSettings.vue";
import CountersValues from "@/views/counters_values/CountersValues.vue";
import {
  checkUserAccess,
  checkContractId,
  checkCompanyId,
  tryAuth
} from "@/router/helpers";

Vue.use(VueRouter);

// Представления и компоненты для страницы "Авторизация".
const Auth = () => import("@/views/Auth.vue");
const SignInForm = () => import("@/components/for-auth-view/SignInForm.vue");
const SignUpForm = () => import("@/components/for-auth-view/SignUpForm.vue");
const PasswordRecoveryForm = () =>
  import("@/components/for-auth-view/PasswordRecoveryForm.vue");
const PasswordRecoveryConfirm = () =>
  import("@/components/for-auth-view/PasswordRecoveryConfirm.vue");

// Представления для страниц, содержащих информацию о договоре и организации.
const ContractCard = () => import("@/views/contracts/ContractCard.vue");
const CompanyModule = () => import("@/views/CompanyModule.vue");
const CompanyCard = () => import("@/views/CompanyCard.vue");
const Documents = () => import("@/views/Documents.vue");
const MonthlyPlan = () => import("@/views/MonthlyPlan.vue");
const Appeals = () => import("@/views/Appeals.vue");
const AppealChat = () => import("@/views/AppealChat.vue");
const TurnoversAndReports = () => import("@/views/TurnoversAndReports.vue");
const Payments = () => import("@/views/Payments.vue");
const SuccessPayment = () => import("@/views/payments/SuccessPayment.vue");
const FailPayment = () => import("@/views/payments/FailPayment.vue");
const HourlyConsumption = () => import("@/views/HourlyConsumption.vue");
const Notifications = () => import("@/views/Notifications.vue");
const PageNotFound = () => import("@/views/PageNotFound.vue");

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "home",
    beforeEnter: tryAuth
  },
  {
    path: "/auth",
    name: "auth",
    component: Auth,
    redirect: { name: "signIn" },
    children: [
      { path: "sign-in", name: "signIn", component: SignInForm },
      { path: "sign-up", name: "signUp", component: SignUpForm },
      {
        path: "password-recovery",
        name: "passwordRecovery",
        component: PasswordRecoveryForm
      },
      {
        path: "password-recovery-confirm",
        name: "passwordRecoveryConfirm",
        component: PasswordRecoveryConfirm,
      },
    ]
  },
  {
    path: "/contracts",
    component: ContractModule,
    children: [
      {
        path: "",
        name: "myContracts",
        component: MyContracts,
        meta: { requiresAuth: true }
      },
      {
        path: "/payments/success",
        name: "successPayments",
        component: SuccessPayment,
        meta: { requiresAuth: true }
      },
      {
        path: "/payments/fail",
        name: "failPayments",
        component: FailPayment,
        meta: { requiresAuth: true }
      },
      {
        path: ":contractId(\\d+)",
        name: "contractCard",
        component: ContractCard,
        meta: { requiresAuth: true },
        beforeEnter: checkContractId
      },
      {
        path: ":contractId(\\d+)/documents",
        name: "documents",
        component: Documents,
        meta: { requiresAuth: true },
        beforeEnter: checkContractId
      },
      {
        path: ":contractId(\\d+)/hourly-consumption",
        name: "hourlyConsumption",
        component: HourlyConsumption,
        meta: { requiresAuth: true },
        beforeEnter: checkContractId
      }
    ]
  },
  {
    path: "/companies",
    component: CompanyModule,
    redirect: {
      name: "companyCard",
      params: { companyId: store.getters["organization/id"] }
    },
    children: [
      {
        path: ":companyId(\\d+)",
        name: "companyCard",
        component: CompanyCard,
        meta: { requiresAuth: true },
        beforeEnter: checkCompanyId
      },
      {
        path: ":companyId(\\d+)/turnovers-and-reports",
        name: "turnoversAndReports",
        component: TurnoversAndReports,
        meta: { requiresAuth: true },
        beforeEnter: checkCompanyId
      }
    ]
  },
  {
    path: "/counters_values",
    name: "countersValues",
    component: CountersValues,
    meta: { requiresAuth: true }
  },
  {
    path: "/account-settings",
    name: "accountSettings",
    component: AccountSettings,
    meta: { requiresAuth: true }
  },
  { path: "/page-not-found", name: "pageNotFound", component: PageNotFound },
  // TODO: подготовить "надлежащий" маршрут.
  {
    path: "/monthly-plan",
    name: "monthlyPlan",
    component: MonthlyPlan,
    meta: { requiresAuth: true }
  },
  {
    path: "/payments",
    name: "payments",
    component: Payments,
    meta: { requiresAuth: true }
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    meta: { requiresAuth: true }
  },
  {
    path: "/appeals",
    component: AppealsForRoot,
    redirect: {
      name: "appealsByDogovor",
      params: { contractId: store.getters["contract/id"] }
    },
    children: [
      {
        path: ":contractId",
        name: "appealsByDogovor",
        component: Appeals,
        meta: { requiresAuth: true },
        beforeEnter: checkContractId
      },
      {
        path: ":contractId/appealChat/:appealId",
        name: "appealChat",
        component: AppealChat,
        meta: { requiresAuth: true },
        beforeEnter: checkContractId
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(checkUserAccess);

export { router };
